import React, { useEffect } from 'react';
import './404.css';

const NotFound = () => {
  return (
    <>
      <section>
        <h2>404 - Not Found</h2>
        <p>申し訳ありませんが、お探しのページは見つかりませんでした。</p>
        <a href="/">ホームに戻る</a>
      </section>
    </>
  );
};

export default NotFound;