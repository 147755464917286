import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    return (
        <header>
            <img className="logo" src="/img/common/logo.svg"></img>
            <h1 className="logo-text garamond">RubyMagic</h1>
        </header>
    );
};

export default Header;