import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
        <div class="loading">
          <img src="/img/common/logo.svg"></img>
        </div>
    );
};

export default Loading;