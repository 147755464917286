import React, { useEffect } from 'react';
import './Home.css';

const Home = () => {
  useEffect(() => {
    document.body.id = 'home';
  }, []);

  return (
    <>      
      <section className="message">
      <p>RubyMagic株式会社のウェブサイトにお越しいただき、誠にありがとうございます。</p>
      <p>私たちのミッションは、ITとデザインを融合させ、新しい価値を提供することです。<br/>
      小売・サービス業界に特化したスピード感あるWEBシステム開発、エンジニアやディレクター向けの実践的なデザイン教育、さらにプログラミング技術を活用した革新的なテキスタイルデザインの創作と販売を通じて、世の中を少し楽しく、少し便利にするお手伝いをしています。</p>
      <p>私たちが大切にしているのは、ワクワクし、楽しみながら共に成長していくこと。<br/>
      ITとデザイン、それぞれの力を活かし、シンプルで効果的な解決策をお届けすることを心がけています。<br/>
      日々の課題を乗り越え、未来に向けた一歩を共に進めるパートナーとして、柔軟で親しみやすいサービスを提供します。</p>
      <p>「少し楽しく、少し便利に」をモットーに、さまざまな挑戦を楽しみながら、新しい価値を創造していきたいと考えています。<br/>
      どうぞお気軽にご相談ください。一緒に未来のアイデアを形にしていきましょう。</p>
      </section>

      <hr/>

      <section>
        <h2>会社概要</h2>

        <dl className="styled-dl">
          <dt>会社名</dt>
          <dd className="border"></dd>
          <dd><ruby>RubyMagic<rp>(</rp><rt>ルビーマジック</rt><rp>)</rp> </ruby>株式会社<br/>（英文名称: Ruby Magic Corporation）</dd>

          <dt>代表者</dt>
          <dd className="border"></dd>
          <dd>代表取締役社長　小川尚子</dd>

          <dt>所在地</dt>
          <dd className="border"></dd>
          <dd>〒150-0001<br/>東京都渋谷区神宮前6丁目23-4<br/>桑野ビル2階</dd>

          <dt>事業内容</dt>
          <dd className="border"></dd>
          <dd>
            <ul>
              <li>小売業・サービス業向けWEBシステムの開発</li>
              <li>WEBシステム開発における業務委託・コンサルティングサービス</li>
              <li>IT人材向けデザインおよびシステム開発の教育</li>
              <li>プログラミング技術を活用したテキスタイルデザインの制作・販売</li>
            </ul>
          </dd>
          
          <dt>設立年月日</dt>
          <dd className="border"></dd>
          <dd>2024年11月11日</dd>

          <dt>資本金</dt>
          <dd className="border"></dd>
          <dd>200万円</dd>
        </dl>
      </section>

      <hr/>

      <section>
        <h2>お問い合わせ</h2>
        <div><a href="https://forms.gle/sRVrYbVEZMcn6qAEA" target="_blank">こちらのフォーム</a>よりお問い合わせください。</div>
      </section>

      <hr/>
    </>
  );
};

export default Home;