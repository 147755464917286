// import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Loading from './components/Loading/Loading';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import NotFound from './pages/404/404'; // 404ページ
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load', () => setIsLoading(false));
  }, []);

  return (
    <Router>

        <>
          <div className="kv-left"></div>
          <div className="content">
            <Header />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <Footer />
          </div>
          <div className="kv-right"></div>
        </>


    </Router>
  );
}

export default App;
